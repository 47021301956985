export const setCookie = (name, value) => {
  const expires = 30 * 1000 * 60 * 60 * 24; // 30 days
  const today = new Date();
  const expiryDate = new Date(today.getTime() + expires);
  document.cookie = `${name}=${value};path=/;expires=${expiryDate.toGMTString()};`;
};

export const getCookie = (name) => {
  const cookies = Object.assign(
    {},
    ...document.cookie.split("; ").map((cookie) => {
      const name = cookie.split("=")[0];
      const value = cookie.split("=")[1];

      return { [name]: value };
    })
  );

  return cookies[name];
};
