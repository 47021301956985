<template>
  <div>
    <div class="px-2 md:mx-auto mt-4">
      <div class="flex items-center flex-wrap gap-4">
        <div class="flex items-center justify-between w-56 mr-4">
          <label class="w-16 font-semibold">{{ $t("week") }}</label>
          <t-select
            class="w-44"
            v-model="filter.week"
            :options="weeks"
          ></t-select>
        </div>
        <div class="flex items-center justify-between w-56">
          <label class="w-16 font-semibold">{{ $t("job_type") }}</label>
          <t-select
            class="w-44"
            v-model="filterJobType"
            :options="jobFilters"
            value-attribute="id"
            text-attribute="name"
          ></t-select>
        </div>
      </div>
      <div class="flex py-6 justify-end">
        <button
          @click="toggleLegendModal = true"
          class="mx-4 text-sm text-blue-500"
        >
          <i class="fas fa-question-circle"></i>
          {{ $t("help") }}
        </button>
        <button
          @click="showSpecialJobModal"
          class="mx-4 text-sm"
          :class="isSpecialJobUnread ? 'text-red-500' : 'text-blue-500'"
        >
          <i class="fas fa-heart"></i>
          {{ $t("special_jobs") }}
          <i
            v-if="isSpecialJobUnread"
            class="fas fa-exclamation-circle text-xs text-red-500"
          ></i>
        </button>
      </div>
    </div>
    <div class="md:px-2">
      <table class="w-full border-b border-gray-300 shadow-sm">
        <thead class="darkgray-bg-color h-16">
          <tr>
            <th class="w-20"></th>
            <th class="w-20"></th>
            <th v-for="(date, key) in dates" :key="key">
              <button
                v-if="isAdminOrSuperUser"
                class="text-xs font-semibold text-black"
                @click="$router.push(`/comment/${date}`)"
              >
                {{ `${key} ${date}` }}
              </button>
              <span v-else class="text-xs font-semibold text-black">
                {{ `${key} ${date}` }}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="isAdminOrSuperUser">
            <tr>
              <td colspan="2">
                <strong>{{ $t("day_template") }}</strong>
              </td>
              <td
                class="text-xs"
                v-for="(date, key, index) in dates"
                :key="key"
              >
                <t-select
                  class="w-44"
                  :value="templateFilter[date]"
                  :options="templates"
                  value-attribute="id"
                  text-attribute="name"
                  v-bind:disabled="isUnpublished"
                  v-bind:placeholder="getPlaceholder"
                  @click="allowChange = true"
                  @change="templateChange($event, date, index + 1)"
                ></t-select>
              </td>
            </tr>
          </template>
          <template v-if="isAdminOrSuperUser">
            <tr class="comment-tr">
              <td colspan="2"></td>
              <td
                class="text-xs"
                valign="top"
                v-for="(date, key) in dates"
                :key="key"
              >
                {{ getCommentByDate(date, "text") }}
              </td>
            </tr>
            <tr class="comment-tr">
              <td colspan="2"></td>
              <td
                class="text-xs"
                valign="top"
                v-for="(date, key) in dates"
                :key="key"
              >
                <ul class="list-disc mx-3" v-if="hasHrNotifications(date)">
                  <li
                    v-for="(notification, notifKey) in getHrNotificationsByDate(
                      date
                    )"
                    :key="notifKey"
                  >
                    {{
                      `${getTimeFromDateTime(notification.datetime)} ${
                        notification.message
                      }`
                    }}
                  </li>
                </ul>
              </td>
            </tr>
          </template>
          <tr class="comment-employee-tr">
            <td colspan="2"></td>
            <td
              class="text-xs"
              valign="top"
              v-for="(date, key) in dates"
              :key="key"
            >
              {{ getCommentByDate(date, "text_employees") }}
            </td>
          </tr>
          <tr class="breakline">
            <td colspan="9"></td>
          </tr>
          <template
            v-for="(groupedFullchedule, jobName) in groupedFullchedules"
          >
            <tr
              v-for="(time, timeKey) in groupedFullchedule.times"
              :key="`${jobName}-${timeKey}`"
            >
              <td class="text-sm font-semibold">
                {{
                  isJobFirstTimeSlot(groupedFullchedule.times, timeKey)
                    ? jobName
                    : ""
                }}
              </td>
              <td class="text-sm">{{ timeKey }}</td>
              <template v-for="date in dates">
                <td v-if="!time[date]" :key="`empty-td-${date}`"></td>
                <td
                  v-else
                  class="text-sm"
                  valign="top"
                  :key="`schedules-td-${date}`"
                >
                  <div
                    class="mb-1 flex flex-col"
                    v-for="schedule in time[date]"
                    :key="`schedule-${schedule.id}`"
                  >
                    <template v-if="isAdminOrSuperUser">
                      <router-link
                        :to="`/main/${date}/${schedule.id}`"
                        class="text-sm font-semibold block"
                        :class="
                          schedule | scheduleState(false, isAdminOrSuperUser)
                        "
                      >
                        {{
                          schedule.user_id
                            ? schedule.name || $t("unknown_user")
                            : `${$t("personnel")}?`
                        }}
                        <template v-if="isLocationBar5">
                          <span v-for="(tag, key) in schedule.tags" :key="key">
                            <span v-for="index in tag.qty" :key="index">
                              {{ getTagEmoji(tag) }}
                            </span>
                          </span>
                        </template>
                        <span v-if="schedule.holiday">
                          &nbsp;<i class="fas fa-exclamation text-xs"></i>
                        </span>
                        <span v-if="schedule.late">
                          &nbsp;<i class="far fa-clock text-xs"></i>
                        </span>
                        <span v-if="schedule.join_dinner">
                          &nbsp;<i class="fas fa-utensils text-xs"></i>
                        </span>
                      </router-link>
                      <span
                        class="whitespace-nowrap text-xs"
                        :class="
                          schedule | scheduleState(true, isAdminOrSuperUser)
                        "
                      >
                        {{ `(${timeKey} - ${schedule.ending_text})` }}
                      </span>
                    </template>
                    <template v-else>
                      <template v-if="!schedule.name">
                        <div class="flex flex-wrap">
                          <span class="text-red font-semibold block mr-1"
                            >{{ $t("personnel") }}?</span
                          >
                          <button
                            class="
                              text-sm text-green
                              font-semibold
                              whitespace-nowrap
                            "
                            v-if="
                              !isAuthUserScheduledDate(date, schedule) &&
                              isSameJobAsAuthUser(schedule.job_id)
                            "
                            @click="planAuthUser(schedule.id)"
                          >
                            &laquo; {{ $t("plan_me") }}
                          </button>
                        </div>
                      </template>
                      <span
                        v-else
                        class="text-sm font-semibold"
                        :class="
                          schedule | scheduleState(false, isAdminOrSuperUser)
                        "
                      >
                        {{ schedule.name }}
                      </span>
                      <span
                        class="whitespace-nowrap text-xs"
                        :class="
                          schedule | scheduleState(true, isAdminOrSuperUser)
                        "
                      >
                        {{ `(${timeKey} - ${schedule.ending_text})` }}
                      </span>
                    </template>
                  </div>
                </td>
              </template>
            </tr>
            <tr class="breakline" :key="`${jobName}-breakline`">
              <td colspan="9"></td>
            </tr>
          </template>
          <tr class="breakline">
            <td colspan="9"></td>
          </tr>
          <template v-if="isAdminOrSuperUser">
            <tr class="tr-actions">
              <td colspan="2" class="text-sm">{{ $t("add") }}</td>
              <td
                class="bg-darkgrey"
                v-for="(date, key) in dates"
                :key="`add-${key}`"
              >
                <router-link
                  :to="`/main/add/${filter.week}/${date}`"
                  class="text-sm text-blue-500"
                >
                  {{ $t("add") }}
                </router-link>
              </td>
            </tr>
            <tr class="tr-actions">
              <td colspan="2" class="text-sm">{{ $t("free_days") }}</td>
              <td
                class="bg-darkgrey"
                v-for="(date, key) in dates"
                :key="`freedays-${key}`"
              >
                <span
                  class="block text-xs"
                  v-for="(employee, freeDayDate) in employeeFreeDays[date]"
                  :key="`${key}-${freeDayDate}`"
                >
                  {{ employee }}
                </span>
              </td>
            </tr>
            <tr class="tr-actions">
              <td colspan="2" class="text-sm">{{ $t("holidays") }}</td>
              <td
                class="bg-darkgrey"
                v-for="(date, key) in dates"
                :key="`holidays-${key}`"
              >
                <span
                  class="block text-xs"
                  v-for="(holiday, index) in groupedHolidays[date]"
                  :key="`${key}-${index}`"
                >
                  {{ holiday.user.name }}
                </span>
              </td>
            </tr>
            <tr class="tr-actions">
              <td colspan="2" class="text-sm">{{ $t("extra_personnel") }}</td>
              <td
                class="bg-darkgrey"
                v-for="(date, key) in dates"
                :key="`extra_personnel-${key}`"
              >
                <template
                  v-for="(personnel, index) in groupedExtraPersonnel[date]"
                >
                  <span class="block text-xs" :key="`${key}-${index}-name`">
                    {{ personnel.name }}
                  </span>
                  <span
                    class="block text-xs mb-4"
                    :key="`${key}-${index}-time`"
                  >
                    [{{ personnel.from_text }} : {{ personnel.until_text }}]
                  </span>
                </template>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <!-- Legend Modal -->
    <legend-modal v-model="toggleLegendModal" />
    <!-- ./Legend Modal -->

    <!-- SpecialJob Modal -->
    <t-modal
      v-if="specialJob"
      v-model="toggleSpecialJobModal"
      header="Special Job"
    >
      <p class="mx-3" :inner-html.prop="specialJob.body | breakline"></p>
      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button
            type="button"
            class="text-sm mr-3"
            @click="toggleSpecialJobModal = false"
          >
            {{ $t("close") }}
          </t-button>
          <t-button
            type="button"
            class="text-sm"
            v-if="isAdminOrSuperUser"
            variant="primary"
            @click="$router.push(`/special-job/add`)"
          >
            {{ $t("update") }}
          </t-button>
        </div>
      </template>
    </t-modal>
    <!-- ./SpecialJob Modal -->
  </div>
</template>

<script>
import LegendModal from "@/components/LegendModal.vue";
import { horecaLocations, tagEmojis, weekDays } from "@/constants/misc";
import { actions, getters } from "@/constants/state";
import {
  checkAvailability,
  setDayTemplate,
} from "@/services/fullschedule-service";
import { checkIfPublished } from "@/services/published-schedule-service";
import { getByDateRange } from "@/services/template-schedule-service";
import { getCookie, setCookie } from "@/utils/cookie";
import {
  getEndWeekDuration,
  getWeekDatesFromDate,
  getWeekOptions,
} from "@/utils/misc";
import moment from "@/utils/moment-utc";
import { getGroupedHolidays } from "@/utils/user";
import * as _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { LegendModal },
  data: () => ({
    initialLoad: true,
    templateFilter: {},
    toggleLegendModal: false,
    toggleSpecialJobModal: false,
    isSpecialJobUnread: false,
    isUnpublished: false,
  }),
  mounted() {
    this.loadData();

    this.isSpecialJobUnread =
      !_.isEmpty(this.specialJob) &&
      _.get(this.specialJob, "created_at") !==
        getCookie("roosterv2_special_job_date");
  },
  methods: {
    ...mapActions({
      getFullSchedules: actions.FULLSCHEDULES_FETCH_ACTION,
      getComments: actions.COMMENTS_FETCH_ACTION,
      getHrNotifications: actions.HRNOTIFICATIONS_FETCH_ACTION,
      getHolidays: actions.HOLIDAYS_FETCH_APPROVED_ACTION,
      getExtraPersonnel: actions.FULLSCHEDULES_FETCH_EXTRA_PERSONNEL_ACTION,
      getFreeDays: actions.FREEDAYS_FETCH_OVERVIEW_ACTION,
      planUser: actions.FULLSCHEDULES_PLAN_USER_ACTION,
      setMainFilterAction: actions.SESSION_SET_MAIN_FILTER_ACTION,
      getSpecialJob: actions.SPECIALJOB_FETCH_LATEST_ACTION,
      getUserJobs: actions.JOBS_FETCH_USER_JOBS_ACTION,
      getTemplates: actions.TEMPLATES_FETCH_ACTION,
    }),
    async loadData() {
      this.$emit("setLoading", true);
      this.allowChange = false;

      try {
        const payload = {
          from: this.defaultWeekView,
          until: getEndWeekDuration(this.defaultWeekView),
          jobFilterId: this.filter.jobFilterId,
        };
        const userData = async () => {
          if (this.isAdminOrSuperUser) {
            return await Promise.all([
              this.getHolidays(payload),
              this.getExtraPersonnel(payload),
              this.getFreeDays(payload),
            ]);
          }

          await this.getUserJobs({ userId: this.user.user.id });
        };

        await Promise.all([
          this.getComments(payload),
          this.getHrNotifications(payload),
          this.getSpecialJob(),
          this.getFullSchedules({
            ...payload,
            jobFilterId: this.filter.jobFilterId,
          }),
          this.getTemplates(),
          this.getTemplateSchedule(),
          userData(),
        ]);
      } catch (error) {
        console.error("DEBUG: LoadData", error);
      }

      this.initialLoad = false;
      this.$emit("setLoading", false);
    },
    async getTemplateSchedule() {
      this.setTemplateFilter();

      try {
        const isPublished = await checkIfPublished({
          start: _.get(this.dates, this.$t("monday")),
          jobFilterId: this.filter.jobFilterId,
        });

        this.isUnpublished = !_.get(isPublished, "data");

        const { data } = await getByDateRange({
          from: _.get(this.dates, this.$t("monday")),
          until: _.get(this.dates, this.$t("sunday")),
          jobFilterId: this.filter.jobFilterId,
        });

        const dates = {};
        _.map(this.templateFilter, (value, date) => {
          const schedule = _.filter(
            data,
            (schedule) =>
              schedule.date === date &&
              schedule.job_filter_id === this.filter.jobFilterId
          );

          const scheduleDate = this.isUnpublished
            ? []
            : _.get(schedule, "0.template_id");

          dates[date] = scheduleDate;
        });

        this.templateFilter = dates;
        this.initialLoad = true;
      } catch (error) {
        console.error("DEBUG: getTemplateSchedule", error);
      }
    },
    setTemplateFilter() {
      const dates = {};
      _.map(Object.values(this.dates), (date) => _.set(dates, date, null));
      this.templateFilter = dates;
    },
    async templateChange(newTemplateId, date, day) {
      if (!this.allowChange) {
        return;
      }

      if (["string", "number"].includes(typeof newTemplateId)) {
        this.templateFilter[date] = newTemplateId;

        try {
          await setDayTemplate({
            newTemplateId,
            jobFilterId: this.filter.jobFilterId,
            from: date,
            until: date,
            day,
          });
          await this.getFullSchedules({
            from: this.defaultWeekView,
            until: getEndWeekDuration(this.defaultWeekView),
            jobFilterId: this.filter.jobFilterId,
          });
        } catch (error) {
          console.error("DEBUG: scenarioChange", error);
        }
      }
    },
    isSameJobAsAuthUser(jobId) {
      return _.some(this.userJobs, { id: jobId });
    },
    confirmation() {
      return this.$dialog.confirm({
        text: this.user.user.always
          ? this.$i18n.t("plan_me_in_fulltimer_confirmation")
          : this.$i18n.t("plan_me_in_parttimer_confirmation"),
        icon: "info",
      });
    },
    async planAuthUser(scheduleId) {
      const { data: isAvailable } = await checkAvailability(
        scheduleId,
        this.user.user.id
      );

      if (!isAvailable) {
        const { isCancel } = await this.confirmation();

        if (isCancel) {
          return;
        }
      }

      this.$emit("setLoading", true);

      try {
        await this.planUser({ id: scheduleId, userId: this.user.user.id });
      } catch (error) {
        console.error("DEBUG: planAuthUser", error);
      }

      this.$emit("setLoading", false);
    },
    showSpecialJobModal() {
      if (!_.isEmpty(this.specialJob)) {
        setCookie("roosterv2_special_job_date", this.specialJob.created_at);
        this.isSpecialJobUnread = false;
      }

      this.toggleSpecialJobModal = true;
    },
    isJobFirstTimeSlot(times, time) {
      return _.first(Object.keys(times)) === time;
    },
    getCommentByDate(date, key) {
      return _.get(_.find(this.comments, { date }), key) || "";
    },
    getHrNotificationsByDate(date) {
      const notifications = _.filter(
        this.hrNotifications,
        (notification) =>
          moment(notification.datetime).format("YYYY-MM-DD") === date
      );

      return notifications || [];
    },
    hasHrNotifications(date) {
      return _.some(
        this.hrNotifications,
        (notification) =>
          moment(notification.datetime).format("YYYY-MM-DD") === date
      );
    },
    getTimeFromDateTime(datetime) {
      const momentDate = moment(datetime);

      return !momentDate.isValid() ? null : momentDate.format("HH:mm");
    },
    isAuthUserScheduledDate(date, schedule) {
      return _.some(this.fullSchedules, (fullSchedule) => {
        if (schedule.is_buddy_job) {
          return (
            moment(fullSchedule.start).format("YYYY-MM-DD") === date &&
            this.user.user.id == fullSchedule.user_id &&
            fullSchedule.is_buddy_job
          );
        }

        return (
          moment(fullSchedule.start).format("YYYY-MM-DD") === date &&
          this.user.user.id == fullSchedule.user_id &&
          !fullSchedule.is_buddy_job
        );
      });
    },
    getTagEmoji(tag) {
      switch (tag.tagid) {
        case tagEmojis.SATURDAY_HERO:
          return "🕺";
        case tagEmojis.TOP_AVAILABILITY:
          return "💪";
      }
    },
  },
  computed: {
    ...mapGetters({
      user: getters.AUTH_USER_GETTER,
      comments: getters.COMMENTS_GETTER,
      hrNotifications: getters.HRNOTIFICATIONS_GETTER,
      specialJob: getters.SPECIALJOB_LATEST_GETTER,
      fullSchedules: getters.FULLSCHEDULES_GETTER,
      freeDays: getters.FREEDAYS_OVERVIEW_GETTER,
      isAdminOrSuperUser: getters.AUTH_IS_ADMIN_OR_SUPERUSER_GETTER,
      approvedHolidays: getters.HOLIDAYS_APPROVED_GETTER,
      extraPersonnel: getters.FULLSCHEDULES_EXTRA_PERSONNEL_GETTER,
      filter: getters.SESSION_MAIN_FILTER_GETTER,
      userJobs: getters.JOBS_USER_JOBS_GETTER,
      jobFilters: getters.JOBS_JOB_FILTERS_GETTER,
      templates: getters.TEMPLATES_GETTER,
      horecaLocationId: getters.DOMAIN_LOCATION_ID_GETTER,
    }),
    filterJobType: {
      get() {
        return +this.filter.jobFilterId;
      },
      set(jobFilterId) {
        this.setMainFilterAction({
          ...this.filter,
          jobFilterId,
        });
      },
    },
    groupedHolidays() {
      return getGroupedHolidays(this.approvedHolidays, this.defaultWeekView);
    },
    employeeFreeDays() {
      return _.reduce(
        this.freeDays,
        (total, freeDay) => {
          const date = moment(freeDay.from).format("YYYY-MM-DD");
          const employees = [..._.get(total, date, []), freeDay.name];
          return {
            ...total,
            [date]: employees.sort(),
          };
        },
        []
      );
    },
    weeks() {
      return getWeekOptions(this.isAdminOrSuperUser);
    },
    groupedFullchedules() {
      return _.reduce(
        this.fullSchedules || [],
        (total, sched) => {
          const startDate = moment(sched.start).format("YYYY-MM-DD");
          const startTime = moment(sched.start).format("HH:mm");
          const timeSlots = {
            ..._.get(total, `${sched.job_name}.times`, []),
            [startTime]: {
              ..._.get(total, `${sched.job_name}.times.${startTime}`, []),
              [startDate]: [
                ..._.get(
                  total,
                  `${sched.job_name}.times.${startTime}.${startDate}`,
                  []
                ),
                sched,
              ],
            },
          };

          const sortedTimeKeys = _.sortBy(Object.keys(timeSlots), (time) =>
            moment.duration(time).asMinutes()
          );
          let sorted = {};

          for (let time of sortedTimeKeys) {
            sorted[time] = timeSlots[time];
          }

          return {
            ...total,
            [sched.job_name || ""]: { times: sorted },
          };
        },
        {}
      );
    },
    groupedExtraPersonnel() {
      const endingText = {
        "07:00": this.$i18n.t("call"),
        "02:00": this.$i18n.t("close"),
        "02:30": this.$i18n.t("needed"),
      };

      return _.reduce(
        this.extraPersonnel || [],
        (total, personnel) => {
          const from = moment(personnel.from).format("YYYY-MM-DD");
          const untilTime = moment(personnel.until).format("HH:mm");
          return {
            ...total,
            [from]: [
              ..._.get(total, from, []),
              {
                ...personnel,
                from_text: moment(personnel.from).format("HH:mm"),
                until_text: endingText[untilTime] || untilTime,
              },
            ],
          };
        },
        {}
      );
    },
    dates() {
      return getWeekDatesFromDate(this.defaultWeekView);
    },
    defaultWeekView() {
      const filterWeek = moment(this.filter.week).format("YYYY-MM-DD");
      const currentWeek = moment().startOf("isoWeek").format("YYYY-MM-DD");

      return filterWeek === currentWeek &&
        moment().day() === _.get(weekDays, "A.Sunday")
        ? moment(this.filter.week).add(0, "week").format("YYYY-MM-DD")
        : filterWeek;
    },
    getPlaceholder() {
      return this.isUnpublished ? "Publish Week First" : "Select Template";
    },
    isLocationBar5() {
      return this.horecaLocationId == horecaLocations.BAR_5;
    },
  },
  filters: {
    breakline(value) {
      return (value || "").replace(/(\r\n|\n|\r)/gm, "<br>");
    },
    scheduleState(schedule, forTimeSlot = false, isAdminOrSuperUser) {
      if (schedule.canceled || schedule.holiday) {
        return "holiday-or-canceled";
      }

      if (
        (!schedule.user_id && !forTimeSlot) ||
        (schedule.user_id && schedule.always && !schedule.available)
      ) {
        return isAdminOrSuperUser ? "text-red" : "";
      }

      if (
        !_.isEmpty(schedule.tags) &&
        !_.isEmpty(
          _.find(schedule.tags, { tagid: tagEmojis.GOOD_AVAILABILITY })
        )
      ) {
        return "text-green-500";
      }

      if (schedule.hasFutureTimeouts) {
        return "text-purple-500";
      }

      return isAdminOrSuperUser && schedule.user_id ? "text-blue-500" : "";
    },
  },
  watch: {
    filterJobType: {
      async handler() {
        try {
          this.allowChange = false;

          const payload = {
            from: this.defaultWeekView,
            until: moment(this.defaultWeekView)
              .add(6, "d")
              .format("YYYY-MM-DD"),
            jobFilterId: this.filter.jobFilterId,
          };

          await Promise.all([
            this.getFullSchedules(payload),
            this.getTemplateSchedule(),
            this.getTemplates(),
            this.isAdminOrSuperUser &&
              Promise.all([
                this.getHolidays(payload),
                this.getExtraPersonnel(payload),
                this.getFreeDays(payload),
              ]),
          ]);
        } catch (error) {
          console.error("DEBUG: watch filter", error);
        }
      },
    },
    "filter.week": {
      deep: true,
      async handler(oldWeek, newWeek) {
        if (oldWeek === newWeek) {
          return;
        }

        this.$emit("setLoading", true);
        this.allowChange = false;

        try {
          const payload = {
            from: this.defaultWeekView,
            until: moment(this.defaultWeekView)
              .add(6, "d")
              .format("YYYY-MM-DD"),
            jobFilterId: this.filter.jobFilterId,
          };

          await Promise.all([
            this.getFullSchedules(payload),
            this.getComments(payload),
            this.getHrNotifications(payload),
            this.getTemplateSchedule(),
            this.getTemplates(),
            this.isAdminOrSuperUser &&
              Promise.all([
                this.getHolidays(payload),
                this.getExtraPersonnel(payload),
                this.getFreeDays(payload),
              ]),
          ]);
        } catch (error) {
          console.error("DEBUG: watch filters", error);
        }

        this.$emit("setLoading", false);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.holiday-or-canceled {
  color: #bbbbbb;
}

.text-green {
  color: #2b542c;
}

.bg-darkgrey {
  background-color: #dddddd;
}

.text-red {
  color: #ed1c24;
}

.comment-tr {
  background-color: #ffee;
}

.comment-employee-tr {
  background-color: #ffe9cc;
}

.breakline {
  td {
    height: 1px;
    padding: 1px !important;
    margin: 0;
    background-color: #000;
  }
}

td,
th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border: 1px solid #ddd;

  &:nth-child(n + 3) {
    min-width: 150px;
  }
}

tbody td:nth-child(n + 3),
.tr-actions td:nth-child(n + 2) {
  width: 120px;
  word-break: break-word;
}
</style>
